import React, { useEffect, useState } from "react";
import "../../css/footer.css";
import sendEmail from "../../apis/mail";
import { validateFormContact } from "../../util/validateContactForm";
const sendImg = "/imgs/arrow-right.svg";

const quickLinks = [
	// {
	//   title: "Top Products",
	//   subPage: [
	//     {
	//       name: "Learn Japanese",
	//       url: "",
	//     },
	//     {
	//       name: "Learn Korean",
	//       url: "",
	//     },
	//     {
	//       name: "Lifestyle App",
	//       url: "",
	//     },
	//     {
	//       name: "Power Tools",
	//       url: "",
	//     },
	//   ],
	// },
	{
		title: "Quick Links",
		subPage: [
			{
				name: "Brand Assets",
				url: "",
			},
			{
				name: "Jobs",
				url: "",
			},
			{
				name: "Terms of Service",
				url: "",
			},
			{
				name: "Investor Realtions",
				url: "",
			},
		],
	},
	{
		title: "Features",
		subPage: [
			{
				name: "Jobs",
				url: "",
			},
			{
				name: "Brand Assets",
				url: "",
			},
			{
				name: "Investor Realtions",
				url: "",
			},
			{
				name: "Power Tools",
				url: "",
			},
		],
	},
	{
		title: "Resources",
		subPage: [
			{
				name: "Investor Realtions",
				url: "",
			},
			{
				name: "Jobs",
				url: "",
			},
			{
				name: "Terms of Service",
				url: "",
			},
			{
				name: "Brand Assets",
				url: "",
			},
		],
	},
];

function Footer(props) {
	const [formValues, setFormValues] = useState({
		email: "",
	});

	const handleInputChange = (event) => {
		setFormValues((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};

	const [message, setMessage] = useState({
		message: "",
		type: "NONE",
	});

	const handleSubmit = () => {
		if (validateFormContact(formValues)) {
			sendEmail({
				...formValues,
				app_name: "All in one email",
			});
			setMessage({
				message: "We have received your contact",
				type: "SUCCESS",
			});
			setFormValues({
				email: "",
			});
		} else {
			setMessage({
				message:
					"Please input your email and content (not greater than 5000 characters).",
				type: "ERROR",
			});
		}
	};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setMessage({
				message: "",
				type: "NONE",
			});
		}, 5000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [message]);

	return (
		<div className="footer-container">
			{/* <img alt="scratch" className="footer-decor-" src="/imgs/scratch.svg" /> */}
			<div className="footer-quick-link">
				{quickLinks.map((block, index) => (
					<div key={index} className="footer-block">
						<h4 className="footer-block-title">{block.title}</h4>
						<div className="footer-block-links">
							<ul className="block-links-container">
								{block.subPage.map((page, i) => (
									<li key={i} className="block-links-item">
										<a href={page.url}>{page.name}</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				))}

				<div className="footer-block">
					<h4 className="footer-block-title">Newshelter</h4>
					<div className="footer-block-links">
						<ul className="block-links-container">
							<li className="block-links-item">
								You can trust us. We only promo offers.
							</li>
						</ul>
					</div>
					<div className="footer-form">
						<form>
							<div className="form-container">
								<input
									id="input-email"
									name="email"
									type="text"
									value={formValues.email}
									onChange={handleInputChange}
									placeholder="Your email address"
									// disabled={isSending}
									required
								/>
								<button
									onClick={handleSubmit}
									// disabled={isSending}
								>
									<img src={sendImg} alt="submit" />
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className="copyright">
				433 Nguyen Huu Tho, Office Building, Floor 3, Da Nang, Da Nang Vietnam
			</div>
			<div className="copyright">2021 © StarNest</div>
		</div>
	);
}

export default Footer;
